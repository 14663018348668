import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import { setupListeners } from "@reduxjs/toolkit/query"
import counterReducer from "./features/counter/counterSlice"
import authReducer from "./features/auth/authSlice"
import configurationReducer from "./features/configuration/configurationSlice"

import { authenticationApi } from "./services/authentication"
import { clientInfoApi } from "./services/clientInfo"

import { REDUX_DEV_TOOLS } from 'constraints'

const persistConfig = {
  key: "any-z.baseproject",
  version: 1,
  storage,
  blacklist: [
    // pokemonApi.reducerPath, 
    // authenticationApi.reducerPath,
    // companyApi.reducerPath,
    // roleApi.reducerPath,
    // systemApi.reducerPath,
    // functionApi.reducerPath,
    // moduleApi.reducerPath,
    // logApi.reducerPath,
    // refreshTokenApi.reducerPath,
    // userApi.reducerPath,
    // privilegesApi.reducerPath,
    // permissionApi.reducerPath,
    // clientInfoApi.reducerPath,
  ],
  //whitelist: ['language']
};
const appReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  configuration: configurationReducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [clientInfoApi.reducerPath]: clientInfoApi.reducer,
  //  if we need to use more reducers
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    storage.removeItem('persist:any-z.baseproject')
    state = {};
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer: persistedReducer,
  devTools: REDUX_DEV_TOOLS,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([
    authenticationApi.middleware,
    clientInfoApi.middleware,
  ]),
});


// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export default store
export const persistor = persistStore(store)
export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
