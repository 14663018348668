export const WEB_LOCAL_URL = process.env.REACT_APP_WEB_LOCAL_URL
export const API_ENDPOINT_CLIENT_INFO = process.env.REACT_APP_API_ENDPOINT_CLIENT_INFO
export const API_ENDPOINT_POKEMON = process.env.REACT_APP_API_ENDPOINT_POKEMON
export const API_ENDPOINT_SECURITY = process.env.REACT_APP_API_ENDPOINT_SECURITY
export const API_ENDPOINT_HELPDESK = process.env.REACT_APP_API_ENDPOINT_HELPDESK
export const API_ENDPOINT_BASEPROJECT = process.env.REACT_APP_API_ENDPOINT_BASEPROJECT
export const REDUX_DEV_TOOLS = process.env.REACT_APP_REDUX_DEV_TOOLS
export const I18N_DEBUG = process.env.REACT_APP_I18N_DEBUG


